@import "./assets/vendor/bootstrap/css/bootstrap.min.css";
@import "./assets/vendor/bootstrap-icons/bootstrap-icons.css";
@import "./assets/vendor/boxicons/css/boxicons.min.css";
@import "./assets/vendor/glightbox/css/glightbox.min.css";
@import "./assets/vendor/remixicon/remixicon.css";
@import "./assets/vendor/swiper/swiper-bundle.min.css";

@import "./assets/css/style.css";

.why-us-image {
  background-image: url("/assets/img/hero-img.png");
}
